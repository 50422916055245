import logo from './logo.svg';
import './App.css';
import { Header } from './components/Header';
import { CareCommunities } from './pages/CareCommunities';
import { Container } from 'react-bootstrap';

function App() {
  return (
    <div>
      <Header />
      <div className="spacer" />
      <Container>
      <CareCommunities />
      </Container>
    </div>
  );
}

export default App;
