import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, CardGroup, Button } from "react-bootstrap";

export const CareCommunities = () => {
    const [ data, setData ] = useState([]);

    async function getCareCommunities() {
        try {
            const data = await axios.get('https://cdn.contentful.com/spaces/rub0egcj30sf/environments/master/entries?access_token=e1lUI2o9zlPpom6jEPxOmN6ANp81Vgyk5mJ33YlGrXY&content_type=care_community');
            if (data) {
                setData(data.data.items);
                console.log(data.data.includes);
                return data
            }
            else {
                return { msg: 'No Data' }
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getCareCommunities();
    }, [])


  return (
    <div>
      <h1>Care Communities</h1>
      <hr />
      <CardGroup>
        {data && data.map((e, id) => <Card>
          <Card.Img
            variant="top"
            src={e.fields.communityPhotoUrl}
          />
          <Card.Body>
            <Card.Title>{e.fields.communityName}</Card.Title>
            <hr />
            <a href={e.fields.communityUrl}><Button style={{ marginRight: 20 }} variant="primary" type="button" size="lg">
              Login
            </Button>
            </a>
            <a href={e.fields.communityJoinUrl}>
            <Button
              variant="primary"
              type="button"
              size="lg"
            >
              Join
            </Button>
            </a>
          </Card.Body>
        </Card>)}
      </CardGroup>
    </div>
  );
};
