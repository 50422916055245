import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

export const Header = () => {
  return (
    <div>
      <Navbar expand="lg" className="navbarCustom">
        <Container>
          <Navbar.Brand href="#home">
            <img
              style={{ width: '50%'}}
              src="https://res.cloudinary.com/here-to-serve/image/upload/v1635447380/Static/logo.3fc9e322_1_wzciam.png"
              alt="Here to Serve Logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
};
